/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
* {
  overscroll-behavior-y: none;
}
#root {
  height: 100%;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* iframe on body covering body */

body > iframe {
  z-index: 0 !important;
  position: static !important;
  top: 0px;
  left: 0px;
  width: 0 !important;
  height: 0 !important;
  border: none;
}

.milano-image
{
  height:150px;
  width:150px;
}

.pulsing-image {
  animation: pulse 2s ease-in-out infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.draggable-modal-content {
  background-color: #EAE7E4;
  max-height: 100vh;
}

.is-reward-card {
  display: flex;
  padding: 1.5rem;
  gap: 1rem;
}
.is-reward-card.reward-tier-Gold {
  background-color: #E3A65C;
  color: #1C1A1A;
}
.is-reward-card.reward-tier-Bronze {
  background-color: #BD6E61;
  color: #1C1A1A;
}
.is-reward-card.reward-tier-Silver {
  background-color: #A6A9B7;
  color: #1C1A1A;
}
.is-reward-card.reward-tier-SignUp {
  background-color: #161E35;
  color: white;
}

.loyalty-ticket-bg.gold {
  background-color: #E3A65C;
  color: #1C1A1A;
}
.loyalty-ticket-bg.bronze {
  background-color: #BD6E61;
  color: #1C1A1A;
}
.loyalty-ticket-bg.silver {
  background-color: #A6A9B7;
  color: #1C1A1A;
}
.loyalty-ticket-bg.midnight {
  background-color: #161E35;
  color: white;
}
.loyalty-ticket-bg.moonlight80 {
  background-color: #8c9add !important;
  color: #1C1A1A;
}
.loyalty-ticket-bg.saxophone {
  background-color: #796429 !important;
  color: white;
}
.loyalty-ticket-bg.hihat {
  background-color: #ffe39a !important;
  color: #1C1A1A;
}

.loyalty-ticket-bg.hihat20 {
  background-color: #fff9eb !important;
  color: #1C1A1A;
}

.loyalty-ticket-bg.rose {
  background-color: #ff8a85 !important;
  color: #1C1A1A;
}

.loyalty-slider-container.reward-tier-gold {
  background-color: #E3A65C;
  color: #1C1A1A;
}
.loyalty-slider-container.reward-tier-bronze {
  background-color: #BD6E61;
  color: #1C1A1A;
}
.loyalty-slider-container.reward-tier-silver {
  background-color: #A6A9B7;
  color: #1C1A1A;
}
.loyalty-slider-container.reward-tier-signup {
  background-color: #161E35;
  color: white;
}

.loyalty-modal.gold {
  background-color: #E3A65C;
  color: #1C1A1A;
}
.loyalty-modal.bronze {
  background-color: #BD6E61;
  color: #1C1A1A;
}
.loyalty-modal.silver {
  background-color: #A6A9B7;
  color: #1C1A1A;
}
.loyalty-modal.midnight {
  background-color: #161E35;
  color: white;
}
.loyalty-modal.moonlight80 {
  background-color: #8c9add !important;
  color: #1C1A1A;
}
.loyalty-modal.saxophone {
  background-color: #796429 !important;
  color: white;
}
.loyalty-modal.hihat {
  background-color: #ffe39a !important;
  color: #1C1A1A;
}

.loyalty-modal.hihat20 {
  background-color: #fff9eb !important;
  color: #1C1A1A;
}

.loyalty-modal.rose {
  background-color: #ff8a85 !important;
  color: #1C1A1A;
}

.voucher-card .ticket-container-inner .ticket-container-inner-layout.selected.signup {
  background-color: #B7C0EA;
  border: 1px solid #6F80D4;
}

.voucher-card .ticket-container-inner .ticket-container-inner-layout.selected.bronze {
  background-color: #D7B2AC;
  border: 1px solid #BD6E61;
}

.voucher-card .ticket-container-inner .ticket-container-inner-layout.selected.silver {
  background-color: #EBECF7;
  border: 1px solid #A6A9B7;
}

.voucher-card .ticket-container-inner .ticket-container-inner-layout.selected.gold {
  background-color: #ECD3B3;
  border: 1px solid #E3A65C;
}

.twobythree {
  object-fit: cover;
  object-position: 0px 20px;
  aspect-ratio: 2/3
}

.threebyfour {
  object-fit: cover;
  object-position: 20% 20%;
  aspect-ratio: 3/4
}

.fourbyfive {
  object-fit: cover;
  /* object-position: -45px 30px; */
  aspect-ratio: 4/5;
}

.threebytwo {
  object-fit: cover;
  /* object-position: -45px 30px; */
  aspect-ratio: 3/2;
}

@media only screen and (max-width: 960px) {
  .threebytwo-desktop {
    object-fit: cover;
    object-position: -45px 20px;
    aspect-ratio: 3/2
  }
}

.svg-container {
  width: 100%; /* Set the desired width of the container */
  height: 0; /* Allow the height to be determined by the aspect ratio */
  padding-bottom: 100%; /* Maintain the aspect ratio (1:1 in this example) */
  position: relative;
  overflow: hidden;
}

.svg-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.is-login {
  font-size: 56px
}

.loyalty-modal.slideOut {
  animation: slideOut 200ms ease-in 1 forwards
}

.filter-modal.slideOut {
  animation: slideOut 200ms ease-in 1 forwards
}

/* NBW start */
/* The following styles are required for badges in Menu Item cards
BasketCards.jsx
The classes are dynamically generated in the code.
Gulp will not find the classes and will think they are not required, thus will skip them and will not generate the rules. 

Please keep here untill they are required*/

.ui-tag-popular {
  background-color: #798ee0;
}
.ui-tag-favourites {
  background-color: #798ee0; /* changed to Popular #D6FAD1; */
}
.ui-tag-new {
  background: #FFE39A;
}

.delivery-details-tippy.tippy-box .tippy-arrow {
    background-color: #1c1a1a; /* $warm-black-100  */
}

.delivery-details-tippy.tippy-box .tippy-arrow::before {
  border-top-color: #1c1a1a; /* $warm-black-100  */
}

/* NBW end */

/*OOP-inApp*/
.has-text-rose-100 {
  color: #FF6D66 !important; /*$rose-100*/
}
.has-text-black {
  color: #1C1A1A !important; /*black-100*/
}
.has-background-malbec-100 {
  background-color: #331A1F !important; /*$malbec-100*/
}
.has-background-transparent{
  background-color: transparent !important;
}

.show-c {
  animation-name: show-container;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  animation-direction: normal;
}
.hide-c {
  animation-name: hide-container;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  animation-direction: normal;
}

@keyframes show-container {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes hide-container {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

img {
  user-select: none;
  -webkit-user-drag: none;
}
